<template>
  <v-container fluid class="pa-0">
    <v-card flat tile max-width="1200" class="mx-auto d-flex flex-column">
      <v-dialog v-model="newPeople" class="ml-auto mx-4">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="ml-auto mx-4 my-6" tile dark depressed color="#2787FF">新增人员</v-btn>
        </template>

        <!-- :info="newWorkList"
        :shipList="shipSelectList"-->
        <dialogCard width="670" titleTxt="新增船舶" @cancel="newPeople = false" @submit="submit">
          <template v-slot:info>
            <v-form v-model="newPeopleValid">
              <v-container fluid class="pa-0 px-12">
                <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">基本信息（必填项）</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="4" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">账号</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="title"
                      placeholder="请输入账号"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">用户名</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="username"
                      placeholder="请输入用户名"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">联系电话</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="phone"
                      placeholder="请输入联系电话"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">操作权限</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="3" sm="12" cols="12" v-for="(item,index) in shipList" :key="index">
                    <v-sheet>
                      <v-checkbox v-model="selected" :label="item.shipname" :value="item.id"></v-checkbox>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </template>
        </dialogCard>
      </v-dialog>

      <!-- 修改用户信息 -->
      <v-dialog v-model="modifyPeople" class="ml-auto mx-4">
        <dialogCard
          width="500"
          titleTxt="修改用户信息"
          @cancel="modifyPeople = false"
          @submit="submitModify"
        >
          <template v-slot:info>
            <v-form v-model="modifyPeopleValid">
              <v-container fluid class="pa-0 px-12">
                <v-row align="center">
                  <v-col cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">用户名</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="modifyInformation.username"
                      placeholder="请输入用户名"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">联系电话</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="modifyInformation.phone"
                      placeholder="请输入联系电话"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </template>
        </dialogCard>
      </v-dialog>

      <!-- 分配查询权限 -->
      <v-dialog v-model="queryPermissions" class="ml-auto mx-4">
        <dialogCard
          width="670"
          titleTxt="分配查询权限"
          @cancel="queryPermissions = false"
          @submit="permissionsSubmit()"
        >
          <template v-slot:info>
            <v-form v-model="queryPermissionsValid">
              <v-container fluid class="pa-0 px-12">
                <v-expansion-panels accordion>
                  <v-expansion-panel v-for="(item,i) in queryPermissionsList" :key="i">
                    <v-expansion-panel-header>
                      <v-checkbox
                        @click.stop="companyCheck(i)"
                        v-model="item.selected"
                        :label="item.firmname"
                        :value="item.selected"
                      ></v-checkbox>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col md="4" v-for="(item2,index) in item.shiplist" :key="index">
                          <v-checkbox
                            v-model="queryPermissionsCheck"
                            :label="item2.shipname"
                            :value="item2.id"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-container>
            </v-form>
          </template>
        </dialogCard>
      </v-dialog>

      <!-- 分配操作权限 -->
      <v-dialog v-model="operationAuthority" class="ml-auto mx-4">
        <dialogCard
          width="670"
          titleTxt="分配操作权限"
          @cancel="operationAuthority = false"
          @submit="permissionsSubmit()"
        >
          <template v-slot:info>
            <v-form v-model="operationAuthorityValid">
              <v-container fluid class="pa-0 px-12">
                <v-expansion-panels accordion>
                  <v-expansion-panel v-for="(item,i) in operationAuthorityList" :key="i">
                    <v-expansion-panel-header>
                      <v-checkbox
                        @click.stop="companyCheck(i)"
                        v-model="item.selected"
                        :label="item.firm"
                        :value="item.selected"
                      ></v-checkbox>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col md="4" v-for="(item2,index) in item.list" :key="index">
                          <v-checkbox
                            v-model="operationAuthorityCheck"
                            :label="item2[0]"
                            :value="item2[1]"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-container>
            </v-form>
          </template>
        </dialogCard>
      </v-dialog>

      <v-data-table
        :headers="headers"
        :items="peopleList"
        hide-default-footer
        class="mx-4"
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="8"
        style="border:2px solid rgba(39,135,255,0.3);"
      >
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="modifyUserInformation(item)">mdi-pencil</v-icon>
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>

        <template v-slot:item.authorityAction="{ item }">
          <div class="d-flex justify-start align-center">
            <v-img
              max-width="20"
              @click="distributionView(item,'inquire')"
              src="../../assets/images/operation_icon_btn.png"
            ></v-img>
            <v-img
              class="ml-6"
              max-width="20"
              @click="distributionView(item,'modify')"
              src="../../assets/images/query_icon_btn.png"
            ></v-img>
          </div>
        </template>
      </v-data-table>

      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          max-width="500"
          outlined
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import dialogCard from "@/components/common/dialogCard";
export default {
  components: {
    dialogCard: dialogCard
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    newPeople: false,
    newPeopleValid: false,
    modifyPeople: false,
    modifyPeopleValid: false,
    queryPermissions: false,
    queryPermissionsValid: false,
    operationAuthority: false,
    operationAuthorityValid: false,
    title: "",
    username: "",
    phone: "",
    modifyInformation: {
      username: "",
      phone: ""
    },
    operationAuthorityList: {},
    operationAuthorityCheck: [],
    queryPermissionsList: {},
    queryPermissionsCheck: [],
    peopleList: [],
    shipList: [],
    selected: [],
    rules: [v => !!v],
    headers: [
      {
        text: "登录名",
        align: "start",
        sortable: false,
        value: "title",
        class: "head-style title"
      },
      { text: "姓名", value: "username", class: "head-style title" },
      { text: "联系电话", value: "phone", class: "head-style title" },
      { text: "用户操作", value: "action", class: "head-style title" },
      { text: "权限操作", value: "authorityAction", class: "head-style title" }
    ]
  }),
  created() {
    this.info();
  },
  methods: {
    // 新建用户
    submit() {
      //新增人员
      if (this.newPeopleValid) {
        this.$server
          .newUserInformation({
            pid: this.infonList.uid,
            title: this.title,
            username: this.username,
            phone: this.phone,
            operation_jur: this.selected
          })
          .then(data => {
            if (data.code == 1) {
              this.bus.$emit("tips", {
                show: true,
                title: "新建成功"
              });
              this.newPeople = false;
              this.info();
            } else {
              this.bus.$emit("tips", {
                show: true,
                title: data.error
              });
            }
          });
      } else {
        this.bus.$emit("tips", {
          show: true,
          title: "请输入必填项"
        });
      }
    },
    //分配查询权限
    permissionsSubmit() {
      this.$server
        .modifyQueryPermissions({
          id: this.modifyInformation.id,
          search_jur: this.queryPermissionsCheck
        })
        .then(data => {
          if (data.code == 1) {
            this.bus.$emit("tips", {
              show: true,
              title: "修改成功"
            });
            this.queryPermissions = false;
            this.info();
          } else {
            this.bus.$emit("tips", {
              show: true,
              title: data.error
            });
          }
        });
    },
    //公司船舶全部选择
    companyCheck(i) {
      this.queryPermissionsList[i].selected = !this.queryPermissionsList[i]
        .selected;
      if (this.queryPermissionsList[i].selected) {
        for (let key in this.queryPermissionsList[i].shiplist) {
          if (
            !(
              this.queryPermissionsCheck.indexOf(
                this.queryPermissionsList[i].shiplist[key].id
              ) > -1
            )
          ) {
            this.queryPermissionsCheck.push(
              this.queryPermissionsList[i].shiplist[key].id
            );
          }
        }
      } else {
        for (let key in this.queryPermissionsList[i].shiplist) {
          this.delete(
            this.queryPermissionsCheck,
            this.queryPermissionsList[i].shiplist[key].id
          );
        }
      }
    },
    deleteItem(arr, i) {
      var index = arr.indexOf(i);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    distributionView(data, type) {
      this.modifyInformation.id = data.id;
      if (type == "inquire") {
        this.$server
          .queryPermissions({
            id: data.id
          })
          .then(data => {
            this.queryPermissionsCheck = data.data.search_jur;
            this.queryPermissionsList = data.data.firmlist;
            this.queryPermissions = true;
          });
      } else {
        this.$server
          .operationAuthority({
            id: data.id
          })
          .then(data => {
            this.operationAuthorityCheck = data.content.user_operation_jur;
            this.operationAuthorityList = data.content.shiplist;
            this.operationAuthority = true;
          });
      }
    },
    // 修改用户信息
    modifyUserInformation(data) {
      this.modifyInformation.id = data.id;
      this.modifyInformation.username = data.username;
      this.modifyInformation.phone = data.phone;
      this.modifyPeople = true;
    },
    // 修改用户信息
    submitModify() {
      if (this.modifyPeopleValid) {
        this.$server
          .modifyUserInformation({
            id: this.modifyInformation.id,
            username: this.modifyInformation.username,
            phone: this.modifyInformation.phone
          })
          .then(data => {
            if (data.code == 1) {
              this.bus.$emit("tips", {
                show: true,
                title: "修改成功"
              });
              this.modifyPeople = false;
              this.info();
            } else {
              this.bus.$emit("tips", {
                show: true,
                title: data.error
              });
            }
          });
      } else {
        this.bus.$emit("tips", {
          show: true,
          title: "请输入必填项"
        });
      }
    },
    info() {
      //获取用户基础信息
      this.infonList = JSON.parse(localStorage.getItem("info"));
      // 获取人员列表
      this.$server.peopleList().then(data => {
        this.peopleList = data.data;
        this.shipList = data.shiplist;
      });
    }
  }
};
</script>

<style>
</style>